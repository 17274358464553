import React from "react"

export default function PrivacyPolicy() {
    const __html = require('./html/PrivacyPolicy.html');
    const template = { __html: __html };
    return (
        <div className="container-fluid">
            <div dangerouslySetInnerHTML={template} />
        </div>
    )
}
