import React, { Component } from "react"
import PrivacyPolicy from "../components/Description/Tos/PrivacyPolicy"
import Layout from "../components/Layout"

export default function Privacy() {
    return (
        <Layout>
            <PrivacyPolicy />
        </Layout>
    )
}
